import * as React from "react";
import Layout from "../components/layout";
import Gallery from "../components/portfolio/gallery";
import Header from "../components/header";

const Portfolio = () => {
  const breadcrumbs = [
    { link: "/", text: "Home" },
    { link: "/portfolio", text: "Portfolio" },
  ];

  return (
    <Layout>
      <Header title="Portfolio" breadcrumbs={breadcrumbs}></Header>
      <Gallery></Gallery>
    </Layout>
  );
};

export default Portfolio;
