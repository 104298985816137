import React from "react";

export default function Gallery() {
  return (
    <section className="portfolio-metro porfolio-section-type-2">
      <div className="buttons-sort buttons-sort--button-type-1">
        <button className="buttons-sort--button active-button" data-filter="*">
          All
        </button>
        <button className="buttons-sort--button" data-filter=".web">
          Web
        </button>
        <button className="buttons-sort--button" data-filter=".heath">
          Heath
        </button>
        <button className="buttons-sort--button" data-filter=".nature">
          Nature
        </button>
        <button className="buttons-sort--button" data-filter=".design">
          Design
        </button>
      </div>
      <div className="four-column-container">
        <div
          className="grid"
          data-grid-layout-mode="packery"
          data-grid-transition="0.6"
          data-grid-stagger="140"
        >
          <div className="grid--grid-item grid--grid-item-50 nature web design">
            <div className="grid-item--grid-inner slide-animation">
              <img
                src="http://via.placeholder.com/1920x1280"
                className="img-fluid"
              />
              <a
                className="grid-inner--description-wrapper"
                href="single-project-1.html"
              >
                <div className="description-wrapper--content">
                  <h5 className="content--title">Plant Mockup</h5>
                  <div className="content--info-wrapper">
                    <p className="info-wrapper--category">Nature</p>
                    <span className="info-wrapper--separator">/</span>
                    <p className="info-wrapper--category">Heath</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="grid--grid-item grid--grid-item-25 design heath">
            <div className="grid-item--grid-inner slide-animation">
              <img
                src="http://via.placeholder.com/1920x1280"
                className="img-fluid"
              />
              <a
                className="grid-inner--description-wrapper"
                href="single-project-2.html"
              >
                <div className="description-wrapper--content">
                  <h5 className="content--title">Lamp Mockup</h5>
                  <div className="content--info-wrapper">
                    <p className="info-wrapper--category">Web</p>
                    <span className="info-wrapper--separator">/</span>
                    <p className="info-wrapper--category">Design</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="grid--grid-item grid--grid-item-25 web heath">
            <div className="grid-item--grid-inner slide-animation">
              <img
                src="http://via.placeholder.com/1920x1280"
                className="img-fluid"
              />
              <a
                className="grid-inner--description-wrapper"
                href="single-project-3.html"
              >
                <div className="description-wrapper--content">
                  <h5 className="content--title">Bottles Mockup</h5>
                  <div className="content--info-wrapper">
                    <p className="info-wrapper--category">Tech</p>
                    <span className="info-wrapper--separator">/</span>
                    <p className="info-wrapper--category">Branding</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="grid--grid-item grid--grid-item-25 design">
            <div className="grid-item--grid-inner slide-animation">
              <img
                src="http://via.placeholder.com/1920x1280"
                className="img-fluid"
              />
              <a
                className="grid-inner--description-wrapper"
                href="single-project-1.html"
              >
                <div className="description-wrapper--content">
                  <h5 className="content--title">Fruits Mockup</h5>
                  <div className="content--info-wrapper">
                    <p className="info-wrapper--category">Lifestyle</p>
                    <span className="info-wrapper--separator">/</span>
                    <p className="info-wrapper--category">Sun</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="grid--grid-item grid--grid-item-25 design web">
            <div className="grid-item--grid-inner slide-animation">
              <img
                src="http://via.placeholder.com/1920x1280"
                className="img-fluid"
              />
              <a
                className="grid-inner--description-wrapper"
                href="single-project-2.html"
              >
                <div className="description-wrapper--content">
                  <h5 className="content--title">Cup Mockup</h5>
                  <div className="content--info-wrapper">
                    <p className="info-wrapper--category">Art</p>
                    <span className="info-wrapper--separator">/</span>
                    <p className="info-wrapper--category">Design</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="grid--grid-item grid--grid-item-50 nature">
            <div className="grid-item--grid-inner slide-animation">
              <img
                src="http://via.placeholder.com/1920x1280"
                className="img-fluid"
              />
              <a
                className="grid-inner--description-wrapper"
                href="single-project-3.html"
              >
                <div className="description-wrapper--content">
                  <h5 className="content--title">Plant Mockup</h5>
                  <div className="content--info-wrapper">
                    <p className="info-wrapper--category">Nature</p>
                    <span className="info-wrapper--separator">/</span>
                    <p className="info-wrapper--category">Oxygen</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="grid--grid-item grid--grid-item-25 heath">
            <div className="grid-item--grid-inner slide-animation">
              <img
                src="http://via.placeholder.com/1920x1280"
                className="img-fluid"
              />
              <a
                className="grid-inner--description-wrapper"
                href="single-project-1.html"
              >
                <div className="description-wrapper--content">
                  <h5 className="content--title">Cactus Mockup</h5>
                  <div className="content--info-wrapper">
                    <p className="info-wrapper--category">Nature</p>
                    <span className="info-wrapper--separator">/</span>
                    <p className="info-wrapper--category">Green</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="grid--grid-item grid--grid-item-25 design">
            <div className="grid-item--grid-inner slide-animation">
              <img
                src="http://via.placeholder.com/1920x1280"
                className="img-fluid"
              />
              <a
                className="grid-inner--description-wrapper"
                href="single-project-2.html"
              >
                <div className="description-wrapper--content">
                  <h5 className="content--title">Pineapple Mockup</h5>
                  <div className="content--info-wrapper">
                    <p className="info-wrapper--category">Heath</p>
                    <span className="info-wrapper--separator">/</span>
                    <p className="info-wrapper--category">Art</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="grid--grid-item grid--grid-item-25 web">
            <div className="grid-item--grid-inner slide-animation">
              <img
                src="http://via.placeholder.com/1920x1280"
                className="img-fluid"
              />
              <a
                className="grid-inner--description-wrapper"
                href="single-project-3.html"
              >
                <div className="description-wrapper--content">
                  <h5 className="content--title">Headphones Mockup</h5>
                  <div className="content--info-wrapper">
                    <p className="info-wrapper--category">Digital</p>
                    <span className="info-wrapper--separator">/</span>
                    <p className="info-wrapper--category">Lifestyle</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="grid--grid-item grid--grid-item-25 web heath">
            <div className="grid-item--grid-inner slide-animation">
              <img
                src="http://via.placeholder.com/1920x1280"
                className="img-fluid"
              />
              <a
                className="grid-inner--description-wrapper"
                href="single-project-1.html"
              >
                <div className="description-wrapper--content">
                  <h5 className="content--title">Flowers Mockup</h5>
                  <div className="content--info-wrapper">
                    <p className="info-wrapper--category">Heath</p>
                    <span className="info-wrapper--separator">/</span>
                    <p className="info-wrapper--category">Nature</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
